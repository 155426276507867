
import Navigation from './Navigation'
import Filters from './Filters'
import DataContent from './DataContent'

import ScrollToTop from'./ScrollToTop'

export default function DataView (props) {

  return (
    <>
      <ScrollToTop/>
      <section className='flex flex-col items-center py-4 md:w-[1276px] max-w-[95%] font-light'>
        <Navigation/>
        <Filters/>
        <section className='flex max-w-full flex-col items-center'>
          <div className='md:w-[1276px] max-w-full'>
            <DataContent/>
          </div>
        </section>
      </section>
    </>
  )

}