import { Avatar, List as AList } from 'antd'

export default function List (props) {

  return (
    <div className='w-full'>
      <AList
        dataSource={props.config.children}
        renderItem={(item, idx) => (
          <AList.Item>
            <AList.Item.Meta
              avatar={<Avatar>{ idx + 1 }</Avatar>}
              title={item.title ? item.title : null}
              description={item.text ? item.text : null}
            />
          </AList.Item>
        )}
      />
    </div> 
  )

}