import { Collapse } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'

import DataContent from '../DataContent'

export default function IndexAffordability (props) {

  const questions = [
    {
      key: 1,
      label: 'Avoiding treatment?',
      children: (<div className='text-base font-light pl-4'>Has there been a time in the last three months when you or a member of your household had a health problem that you did not seek treatment for because of its cost?</div>)
    },
    {
      key: 2,
      label: 'Skipping prescribed medications?',
      children: (<div className='text-base font-light pl-4'>Has there been a time in the last three months when you or a member of your household was unable to pay for a prescribed medicine because you did not have enough money to pay for it?</div>)
    },
    {
      key: 3,
      label: 'Difficult access?',
      children: (<div className='text-base font-light pl-4'>If you needed access to quality healthcare today, could you afford it?</div>)
    }
  ]

  const graph = { children:[{
    "question": "",
    "title": "Healthcare Affordability Index",
    "response": {
      "1": "Cost Desperate",
      "2": "Cost Insecure",
      "3": "Cost Secure"
    },
    "tooltips": {
      "1": "Persons reporting recent occurrences of all three of the following: i) being unable to pay for care, ii) being unable to pay for medicine, and iii) lacking immediate access to medical care.",
      "2": "Persons reporting recent occurrences of at least one but not all of the following: i) being unable to pay for care, ii) being unable to pay for medicine, iii) lacking immediate access to medical care.",
      "3": "Persons reporting no recent occurrence of the following: i) being unable to pay for care, ii) being unable to pay for medicine, iii) lacking immediate access to medical care."
    },
    "multiyear": {
      "2021": "afford_index",
      "2022": "afford_index",
      "2023": "afford_index"
    },
    "exclude": ["value_index"],
    "reversed": true,
    "reversedcolors": true
  }]}

  return (
    <section className='flex flex-row flex-wrap gap-16 max-w-full w-full mt-4'>
      <div className='flex-col flex-1 max-w-full min-w-[200px]'>
        <div className='font-heading text-3xl mt-4'>
          What is the Healthcare Affordability Index?
        </div>
        <p className='text-lg mt-4'>
          The Healthcare Affordability Index measures the impact of the high cost of healthcare on treatment and access to care based on the following three questions:
        </p>
        <Collapse items={questions} accordion bordered={false} defaultActiveKey={['1']} className='font-medium text-lg mt-6' expandIcon={({ isActive }) => <CaretRightOutlined style={{color: '#1193b9', fontSize: '1em'}} rotate={isActive ? 90 : 0} />}/>
      </div>
      <div className='flex-col max-w-full flex-1 w-[400px]'>
        <DataContent config={graph}/>
      </div>
    </section>

  )

}