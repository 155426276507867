export default function Text (props) {

  if (props.config.text) {
    const style = props.config.style ? props.config.style : null
    return (
      <div className='flex flex-wrap w-full' style={style}>
        { props.config.text }
      </div> 
    )
  }

  return null

}