import { Carousel } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useRef, useState } from 'react'

import DataContent from '../DataContent'

export default function WellBeingBurden (props) {

  const graph01 = { children: [
    {
      "question": "To what extent are healthcare costs a financial burden for you and your family?",
      "title": "Financial Burden of Healthcare",
      "response": {
        "1": "Major financial burden",
        "2": "Minor financial burden",
        "3": "Not a financial burden"
      },
      "multiyear": {
        "2021": "WH208",
        "2022": "WH208",
        "2023": "WH208"
      },
      "reversed": true,
      "reversedcolors": true
    }
  ]}

  const graph02 = { children: [
    {
      "question": "To what extent are healthcare costs a financial burden for you and your family?",
      "title": "Healthcare Affordability Index & Financial Burden",
      "style": {
        "width": "auto",
        "flex": 6.5
      },
      "type": "multiyear-index",
      "index": "afford_index",
      "response": {
        "1": "Major financial burden",
        "2": "Minor financial burden",
        "3": "Not a financial burden"
      },
      "multiyear": {
        "2021": "WH208",
        "2022": "WH208",
        "2023": "WH208"
      }
    }
  ]}

  const slider = useRef()
  const [$slide, setSlide] = useState(1)

  function prev () {
    slider.current.prev()
  }

  function next () {
    slider.current.next()
  }

  function slideChange (curr, next) {
    setSlide(next + 1)
  }

  return (
    <section className='flex flex-col gap-4 max-w-full w-full mt-4'>
      <div className='flex flex-row flex-wrap justify-between items-center gap-4'>
        <div className='font-heading text-3xl mt-4'>
          Healthcare costs weighing you down?<br/>You are not alone.
        </div>
        <div className='flex flex-row items-center h-[4em] max-h-[4em] gap-4'>
          <span className='text-lg'>
            0{$slide}/02
          </span>
          <div className='flex flex-row items-center gap-2 rounded-full relative bg-slate-200'>
            <button
              id='slide_burden_prev'
              onClick={ prev }
              className='flex w-8 aspect-square items-center justify-center rounded-full bg-[linear-gradient(132deg,#04909C,#007DB1)] transition border border-transparent hover:border-inherit hover:bg-white hover:bg-none hover:text-black text-white text-3xl cursor-pointer'>
              <LeftOutlined className='text-base'/>
            </button>
            <button
              id='slide_burden_next'
              onClick={ next } 
              className='flex w-8 aspect-square items-center justify-center rounded-full bg-[linear-gradient(132deg,#04909C,#007DB1)] transition border border-transparent hover:border-inherit hover:bg-white hover:bg-none hover:text-black text-white text-3xl cursor-pointer'>
              <RightOutlined className='text-base'/>
            </button>
          </div>
        </div>
      </div>
      <Carousel ref={slider} dots={false} beforeChange={slideChange}>
        <div>
          <div className='flex flex-row flex-wrap gap-16'>
            <div className='flex-col flex-1 max-w-full min-w-[200px]'>
              <p className='text-lg mt-4'>
                Healthcare costs have become a major
                financial burden for many families – eating
                away at funds meant for housing, food,
                tuition, retirement or other expenses.
              </p>
            </div>
            <div className='flex-col max-w-full flex-1 w-[400px]'>
              <DataContent config={graph01}/>
            </div>
          </div>
        </div>
        <div>
          <div className='flex flex-row flex-wrap gap-16'>
            <div className='flex-col flex-1 max-w-full min-w-[200px]'>
              <p className='text-lg mt-4'>
                In 2023, more than two-thirds of Americans
                (67%) indicated healthcare was either a
                minor or major financial burden -- up 4
                points from the previous year.
              </p>
            </div>
            <div className='flex-col max-w-full flex-1 w-[400px]'>
              <DataContent config={graph02}/>
            </div>
          </div>
        </div>
      </Carousel>
    </section>

  )

}