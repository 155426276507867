import { Carousel } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import DataContent from '../DataContent'

export default function WellBeingStress (props) {

  const graph01 = { children: [
    {
      "question": "How much stress does the cost of healthcare for you and your family contribute to your daily life?",
      "title": "Stress Associated with Healthcare Costs",
      "response": {
        "1": "A lot of stress",
        "2": "Some stress",
        "3": "Very little stress",
        "4": "No stress at all"
      },
      "multiyear": {
        "2021": "WH209",
        "2022": "WH209",
        "2023": "WH209"
      },
      "reversed": true,
      "reversedcolors": true
    }
  ]}

  const graph02 = { children: [
    {
      "question": "How much stress does the cost of healthcare for you and your family contribute to your daily life?",
      "title": "Healthcare Affordability Index & Stress",
      "style": {
        "width": "auto",
        "flex": 6.5
      },
      "type": "multiyear-index",
      "index": "afford_index",
      "response": {
        "1": "A lot of stress",
        "2": "Some stress",
        "3": "Very little stress",
        "4": "No stress at all"
      },
      "multiyear": {
        "2021": "WH209",
        "2022": "WH209",
        "2023": "WH209"
      }
    }
  ]}

  const slider = useRef()
  const [$slide, setSlide] = useState(1)

  function prev () {
    slider.current.prev()
  }

  function next () {
    slider.current.next()
  }

  function slideChange (curr, next) {
    setSlide(next + 1)
  }

  return (
    <section className='flex flex-col gap-4 max-w-full w-full mt-4'>
      <div className='flex flex-row flex-wrap justify-between items-center gap-4'>
        <div className='font-heading text-3xl mt-4'>
          High Costs, High Stress!
        </div>
        <div className='flex flex-row items-center h-[4em] max-h-[4em] gap-4'>
          <span className='text-lg'>
            0{$slide}/02
          </span>
          <div className='flex flex-row items-center gap-2 rounded-full relative bg-slate-200'>
            <button
              id='slide_stress_prev'
              onClick={ prev }
              className='flex w-8 aspect-square items-center justify-center rounded-full bg-[linear-gradient(132deg,#04909C,#007DB1)] transition border border-transparent hover:border-inherit hover:bg-white hover:bg-none hover:text-black text-white text-3xl cursor-pointer'>
              <LeftOutlined className='text-base'/>
            </button>
            <button
              id='slide_stress_next'
              onClick={ next } 
              className='flex w-8 aspect-square items-center justify-center rounded-full bg-[linear-gradient(132deg,#04909C,#007DB1)] transition border border-transparent hover:border-inherit hover:bg-white hover:bg-none hover:text-black text-white text-3xl cursor-pointer'>
              <RightOutlined className='text-base'/>
            </button>
          </div>
        </div>
      </div>
      <Carousel ref={slider} dots={false} beforeChange={slideChange}>
        <div>
          <div className='flex flex-row flex-wrap gap-16'>
            <div className='flex-col flex-1 max-w-full min-w-[200px]'>
              <p className='text-lg mt-4'>
                Healthcare costs are a major source of daily
                stress. In 2023, nearly half of Americans
                (47%) said the cost of care contributed
                ‘some’ or ‘a lot’ of stress to their lives -- up 7
                points since 2022.
              </p>
            </div>
            <div className='flex-col max-w-full flex-1 w-[400px]'>
              <DataContent config={graph01}/>
            </div>
          </div>
        </div>
        <div>
          <div className='flex flex-row flex-wrap gap-16'>
            <div className='flex-col flex-1 max-w-full min-w-[200px]'>
              <p className='text-lg mt-4'>
                Healthcare is out of reach for <Link id='nav_80m' to='/data/2023' className='link linkm'>80 million
                Americans</Link>. Our data shows that this results
                in <Link id='nav_unmetneed' to='/data/2022/3' className='link linkm'>unmet need in the form of skipped care
                and medications</Link>, which may lead to
                worsening of health outcomes and
                sometimes even fatal outcomes.
                <br/><br/>
                But there is also an indirect, hidden, impact
                on emotional well-being. The exhibit on the
                right shows that in addition to adverse
                physical health outcomes, the high cost of
                care is also associated with worsening
                emotional well-being.
              </p>
            </div>
            <div className='flex-col max-w-full flex-1 w-[400px]'>
              <DataContent config={graph02}/>
            </div>
          </div>
        </div>
      </Carousel>
    </section>
  )

}