import { Tabs } from 'antd'
import { useSetAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'

import WellBeingStress from './WellBeingStress'
import WellBeingBurden from './WellBeingBurden'

import { filters } from '/src/state/state'

export default function WellBeingPage (props) {

  const setFilters = useSetAtom(filters)
  const navigate = useNavigate()

  const tabs = [
    {
      label: 'Stress',
      key: 0,
      children: <WellBeingStress/>
    },
    {
      label: 'Financial Burden',
      key: 1,
      children: <WellBeingBurden/>
    }
  ]

  const onChange = (e) => {
    setFilters({})
    navigate('/trending/1/' + e)
  }

  return (
    <section className='flex flex-row flex-wrap gap-16 max-w-full w-full'>
      <Tabs
        className='flex w-full'
        items={tabs}
        size='middle'
        onChange={onChange}
        defaultActiveKey={0}
        renderTabBar={(tabBarProps, DefaultTabBar) => (
          <DefaultTabBar {...tabBarProps}>
            {(node) => (
              <button {...node.props} key={node.key} id={ 'tab_wellbeing_' + node.key }/>
            )}
          </DefaultTabBar>
        )}
      />
    </section>

  )

}