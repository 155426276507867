import { useQuery, QueryClient } from 'react-query'
import { fromCSV } from 'arquero';

let prefetched = false

export const query_client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const get_config = () => {
  let { status, data, error } = useQuery({
    queryKey: ['config'],
    queryFn: async () => {
      const res = await fetch(`./data/config.json`)
      if (!res.ok)
        throw new Error('Network response was not ok')
      return res.json()
    },
    staleTime: 1 * 60 * 1000, 
    cacheTime: 5 * 60 * 1000
  })

  if (status == 'success' && !prefetched) {
    Object.values(data.reports).forEach((report) => {
      if (report.config != null)
        query_client.prefetchQuery(get_report_params(report.config))
    })
    prefetched = true
  }

  return { status, data, error }
}

const get_report_params = (filename) => {
  return {
    queryKey: ['report', filename],
    queryFn: async () => {
      const res = await fetch(`./data/${filename}`)
      if (!res.ok)
        throw new Error('Network response was not ok')
      return res.json()
    },
    staleTime: 1 * 60 * 1000, 
    cacheTime: 5 * 60 * 1000,
    enabled: (filename != null)
  }
}

export const get_report = (filename) => {
  let { status, data, error } = useQuery(get_report_params(filename))
  return { status, data, error }
}

export const get_data = (year) => {
  let { status, data, error } = useQuery({
    queryKey: ['data', year],
    retry: false,
    queryFn: async () => {
      const res = await fetch(`./data/${year}.csv`)
      if (!res.ok)
        throw new Error('Network response was not ok')
      const text = await res.text()
      return fromCSV(text)
    },
    staleTime: 1 * 60 * 1000, 
    cacheTime: 5 * 60 * 1000
  })

  return { status, data, error }
}