import { atom } from 'jotai'
import { useParams, useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { get_config, get_report } from './requests'

export const MINIMUM_N = 150

export function colorarray (len) {
  switch (len) {
    case 7:
      return ['#013455', '#007DB1', '#83C4E0', '#00929F', '#78D0D9', '#BEC3CA', '#5D6A7A']
    case 6:
      return ['#013455', '#007DB1', '#83C4E0', '#00929F', '#BEC3CA', '#5D6A7A']
    case 5:
      return ['#013455', '#007DB1', '#83C4E0', '#00929F', '#5D6A7A']
    case 4:
      return ['#013455', '#007DB1', '#00929F', '#5D6A7A']
    case 3:
      return ['#013455', '#007DB1', '#00929F']
    case 2:
      return ['#007DB1', '#00929F']
    case 1:
      return ['#007DB1']
  }
}

export function color (len = 7, idx) {
  return colorarray(len)[idx]
}

export function usePageData () {

  const { page } = useParams()
  const { pathname } = useLocation()

  const { status: config_status, data: config_data, error: config_err } = get_config()
  const { curr_tab, curr_page } = 
    useMemo(() => popmatches(config_data, pathname, page), [config_status, page, pathname])

  const report_url = (config_status != 'success' || curr_page == null) ? null : config_data.reports[curr_page.report].config
  const { status: report_status, data: report_data, error: report_err } = get_report(report_url)

  return {
    page,
    config_status,
    config_data,
    report_status,
    report_url,
    report_data,
    curr_tab,
    curr_page
  }

}

export function popmatches (config, pathname, page) {
  let curr_tab = null
  let curr_page = null
  if (config != null && config.hasOwnProperty('tabs')) {
    config.tabs.forEach((tab) => {
      tab.matches = match(pathname, tab.root_url)
      if (tab.matches) {
        curr_tab = tab
        if (tab.hasOwnProperty('pages')) {
          tab.pages.forEach((pg) => {
            if (page == pg.key)
              curr_page = pg
          })
        }
      }
    })
  }
  return { curr_tab, curr_page }
}

export function match (location, root_url) {
  const loc = location.indexOf('/', 1)
  if (loc === -1) return location === root_url
  return location.substring(0, loc) === root_url
}

export const filters = atom({})