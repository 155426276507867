import { Menu } from 'antd'
import { useParams, Link } from 'react-router-dom'

import { get_config } from 'src/state/requests'

export default function Navigation (props) {

  const style = props.config.style ? props.config.style : null

  const { page } = useParams()
  const { status: config_status, data: config_data, error: config_err } = get_config()

  let nav_items = []
  if (config_status == 'success') {
    nav_items = config_data.order.map((rep) => {
      const report = config_data.reports[rep]
      return {
        label: (
          <Link to={'/data/' + rep}>
            { report.name }
          </Link>
        ),
        key: rep
      }
    })
  }

  return (
    <div className='flex flex-wrap w-full' style={style}>
      <Menu
        items={nav_items}
        mode='horizontal'
        theme='dark'
        selectedKeys={[page]}
        defaultSelectedKeys={[page]}
      />
    </div> 
  )

}