import { Link } from 'react-router-dom'
import { useState } from 'react'
import { motion } from 'framer-motion'

export default function AnxietyBurden (props) {

  const years = ['2021', '2022', '2023']

  // Data State

  const [$year, setYear] = useState(props.year || years[years.length - 1])

  // Data
  
  return (
    <>
      <section className='flex flex-row flex-col-reverse justify-center items-center p-8 pt-32 rounded-lg'>

        <div className='flex flex-col max-w-full flex-1 items-center'>
          <div className='flex flex-col items-start max-w-xl mt-8'>
            <motion.div 
              className='text-3xl font-heading'
              initial={{ opacity: 0, y:-50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              31% reported that anxiety contributed 'a great deal' or 'quite a bit' of burden to their everyday lives in the past 30 days.
            </motion.div>
            { /*
            <Link to={'/report/' + $year + '/5'} state={{ fresh: true }}>
              <div className='link link-light text-sm self-start mt-4' href='#'>
                <span className='relative'>LEARN MORE</span>
              </div>
            </Link>
            */ }
          </div>
        </div>

        <div className='flex flex-col flex-1 max-w-full items-center'>
          <div className='relative max-w-[90%]'>
            <img className='h-[400px] max-w-full animate-pulse' src='./images/Stress03-B2.svg'/>
          </div>
        </div>

        {
          /* inverted color percentage
          <div className='flex flex-col flex-1 max-w-full items-center invert'>
            <div className='relative max-w-[90%] bg-black'>
              <img className='invert w-[450px] max-w-full' src='./images/Stress03.svg'/>
              <div 
                className='absolute w-full h-[0%] bottom-0 mix-blend-color-dodge invert bg-rose-500'/>
            </div>
          </div>
          */
        }
      </section>
    </>
  )
  
}