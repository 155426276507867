import { Collapse as ACollapse } from 'antd'

import DataContent from '../DataContent'

export default function Collapse (props) {

  const config = props.config ? props.config : {}
  const label = props.config.label ? props.config.label : ''
  
  return (
    <div className='w-full'>
      <ACollapse
        items={[
          {
            key: '1',
            label: label,
            children: <DataContent config={config}/>
          }
        ]}
      />
    </div> 
  )

}