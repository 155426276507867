import { useParams } from 'react-router-dom'

import ScrollToTop from './ScrollToTop'
import Navigation from './Navigation'
import Filters from './Filters'

import IndexPage from './trending/IndexPage'
import WellBeingPage from './trending/WellBeingPage'

export default function TrendingView (props) {

  const { page, topic } = useParams()

  function getPage (page, topic) {
    switch (true) {
      case (page == 0):
        return <IndexPage/>
      case (page == 1):
        return <WellBeingPage/>
      default:
        return <IndexPage/>
    }
  }

  return (
    <>
      <ScrollToTop/>
      <section className='flex flex-col items-center py-4 md:w-[1276px] max-w-[95%] font-light'>
        <Navigation/>
        <Filters/>
        { getPage(page, topic) }
      </section>
    </>
  )

}