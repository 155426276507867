import { Menu } from 'antd'
import DataContent from '../DataContent'

import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

export default function TabsSlider (props) {

  const config = props.config ? props.config : null
  if (config == null) return null

  const tabs = config.tabs.map((tab, idx) => {
    return {
      label: tab.name,
      key: idx + ''
    }
  })

  const url_index = ('url_index' in config) ? config.url_index : null
  const { page, '*': url_splat } = useParams()
  const url_split = (url_splat != '') ? url_splat.split('/') : []
  const default_key = 
    (url_index in url_split) ?
      (tabs.findIndex((tab) => tab.key == url_split[url_index]) != -1 ?
        url_split[url_index] : 0) : 0
  const navigate = useNavigate()

  const [current, setCurrent] = useState(default_key + '')

  const onClick = (e) => {
    setCurrent(e.key)
    url_split[url_index] = e.key
    navigate('/data/' + page + '/' + url_split.join('/'))
  }

  return (
    <section className='w-full'>
      {
        (tabs.length > 0) ?
          <Menu
            items={tabs}
            mode='horizontal'
            theme='dark'
            selectedKeys={[current]}
            defaultSelectedKeys={[current]}
            onClick={onClick}
          />
        : (tabs.length === 1) ?
          tabs[0].children
        : null
      }
      <DataContent config={config.tabs[current]}/>
    </section> 
  )
  
}