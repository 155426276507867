import DataContent from '../DataContent'

export default function Row (props) {

  const config = props.config ? props.config : {}
  const style = props.config.style ? props.config.style : null

  return (
    <section className='flex flex-wrap w-full' style={style}>
      <DataContent config={config}/>
    </section> 
  )

}