import { Tabs } from 'antd'
import DataContent from '../DataContent'

import { filters } from 'src/state/state'

import { useParams, useNavigate } from 'react-router-dom'
import { useSetAtom } from 'jotai'

export default function TabsBasic (props) {

  const tabBarStyle = props.config.tabBarStyle ? props.config.tabBarStyle : null

  const setFilters = useSetAtom(filters) 

  const config = props.config ? props.config : null
  if (config == null) return null

  const tabs = config.tabs.map((tab, idx) => {
    return {
      label: tab.name,
      key: idx + '',
      children: <DataContent config={tab}/>
    }
  })

  const url_index = config.hasOwnProperty('url_index') ? config.url_index : null
  const { page, '*': url_splat } = useParams()
  const url_split = (url_splat != '') ? url_splat.split('/') : []
  const default_key = 
    (url_index in url_split) ?
      (tabs.findIndex((tab) => tab.key == url_split[url_index]) != -1 ?
        url_split[url_index] : 0) : 0
  const navigate = useNavigate()

  const onChange = (e) => {
    url_split[url_index] = e
    setFilters({})
    navigate('/data/' + page + '/' + url_split.join('/'))
  }
  
  return (
    <section className='flex w-full'>
      {
        (tabs.length > 0) ?
          <Tabs
            className='flex w-full'
            items={tabs}
            size='middle'
            onChange={onChange}
            tabBarStyle={tabBarStyle}
            defaultActiveKey={default_key + ''}
            renderTabBar={(tabBarProps, DefaultTabBar) => (
              <DefaultTabBar {...tabBarProps}>
                {(node) => (
                  <button {...node.props} key={node.key} id={ 'tab_data_' + page + '_' + node.key }/>
                )}
              </DefaultTabBar>
            )}
          />
        : (tabs.length === 1) ?
          tabs[0].children
        : null
      }
    </section> 
  )
  
}