
import { QueryClientProvider } from 'react-query'
import { ConfigProvider } from 'antd'
import {
  createHashRouter,
  RouterProvider
} from 'react-router-dom'

import { query_client } from '../state/requests'

import OverView from './OverView'
import TrendingView from './TrendingView'
import DataView from './DataView'
import ErrorView from './ErrorView'

const theme = {
  token: {
    fontFamily: '"patron", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
    colorBgLayout: '#ebebeb',
    colorPrimary: '#129cc6',
  },
  components: {
    Tag: {
      defaultBg: '#a2cad8',
      defaultColor: '#3a474c'
    },
    Dropdown: {
      zIndexPopup: 100
    }
  }
}

const router = createHashRouter([
  { 
    path: '/',
    element: <OverView/>,
    errorElement: <ErrorView/>
  },
  {
    path: 'trending/:page/:topic/*',
    element: <TrendingView/>,
    errorElement: <ErrorView/>
  },
  {
    path: 'data/:page/*',
    element: <DataView/>,
    errorElement: <ErrorView/>
  },
  {
    path: '*',
    element: <OverView/>,
    errorElement: <ErrorView/>
  }
])

function InnerContent (props) {

  return <RouterProvider router={ router }/> 
  
}

export default function Content (props) {
  
  return (
    <QueryClientProvider client={ query_client }>
      <ConfigProvider theme={ theme }>
        <InnerContent/>
      </ConfigProvider>
    </QueryClientProvider>
  )

}