import DataContent from '../DataContent'

import { useParams } from 'react-router-dom'
import { get_config, get_report } from 'src/state/requests'

import { Spin } from 'antd'

function getReport() {
  const { page } = useParams()
  const { status: config_status, data: config_data, error: config_err } = get_config()
  const report_url = (config_status != 'success') ? null : config_data.reports[page].config
  const { status, data, error } = get_report(report_url)
  return (status != 'success') ? null : data
}

export default function Component (props) {

  const config = getReport()
  if (config == null) {
    return (
      <div className='flex flex-col pt-16 w-full items-center'>
        <Spin size='large'/>
      </div>
    )
  }

  const name = props.config.name ? props.config.name : null
  if (name == null) return null

  const component = {
    children: config.components[name]
  }

  return (
    <DataContent config={component}/>
  )

}