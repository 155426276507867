import { Select } from 'antd'
import { useAtom } from 'jotai'
import { useParams } from 'react-router-dom'

import { get_config, get_report } from 'src/state/requests'
import { filters } from 'src/state/state'

export default function Filters (props) {

  const { page } = useParams()
  const { status: config_status, data: config_data, error: config_err } = get_config()
  const report_url = (config_status != 'success') ? null : config_data.reports[page].config
  const { status: report_status, data: report_data, error: report_err } = get_report(report_url)

  const [ $filters, setFilters ] = useAtom(filters)

  let demographics = []
  if (report_status == 'success') {
    demographics = report_data.demographics.map((demo) => {
      return {
        label: demo.label,
        value: demo.name
      }
    })
    demographics.unshift({
      label: 'All Respondents',
      value: 'all'
    })
  }

  function filter (demo, label) {
    $filters[demo] = label
    setFilters(Object.assign({}, $filters))
  }

  return (
    <div className='flex flex-col items-start w-full'>
      <div className='uppercase text-sm font-bold text-gray-500'>Filters</div>
      <div className='flex w-full items-start gap-4 flex-wrap'>
        {
          (report_status == 'success') ? report_data.demographics.map((demo, idx) => {
            const options = Object.keys(demo.options).map((key) => {
              return {
                label: demo.options[key],
                value: key
              }
            })
            return (
              <Select 
                style={{ width: 200, minWidth: 200 }} 
                className='flex-1'
                key={idx} 
                options={options} 
                mode='multiple'
                placeholder={demo.label}
                showSearch={false}
                allowClear={true}
                value={$filters[demo.name]}
                onChange={(val) => { filter(demo.name, val) }}
              />
            )
          }) : null
        }
      </div>
    </div>
  )

}