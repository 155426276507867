import { Collapse } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'

import DataContent from '../DataContent'

export default function IndexValue (props) {

  const questions = [
    {
      key: 1,
      label: 'System-wide quality of care relative to its cost',
      children: (<div className='text-base font-light pl-4'>Do you think Americans pay too much, too little, or about the right amount for the quality of healthcare they receive?</div>)
    },
    {
      key: 2,
      label: 'Individual quality of care relative to its cost',
      children: (<div className='text-base font-light pl-4'>Do you think your household pays too much, too little, or about the right amount for the quality of healthcare you receive?</div>)
    },
    {
      key: 3,
      label: 'Value of most recent interaction with the healthcare system',
      children: (<div className='text-base font-light pl-4'>Thinking of the most recent time that you received medical care either in-person or remotely, was your experience worth the cost?</div>)
    }
  ]

  const graph = { children: [
    {
      "question": "",
      "title": "Healthcare Value Index",
      "response": {
        "1": "Poor Value",
        "2": "Inconsistent Value",
        "3": "High Value"
      },
      "tooltips": {
        "1": "Persons reporting all three of the following: i) their household pays too much for the quality of care they receive, ii) believes Americans generally pay too much for the quality of the care they receive and iii) their most recent care experience was not worth the cost.",
        "2": "Persons reporting at least one but not all of the following: i) their household pays too much for the quality of care they receive, ii) believes Americans generally pay too much for the quality of the care they receive and iii) their most recent care experience was not worth the cost.",
        "3": "Persons reporting all three of the following: i) their household pays the right amount or too little relative to the quality of care they receive ii) believes Americans generally pay the right amount or too little relative to the quality of care they receive and iii) their most recent care experience was worth the cost."
      },
      "multiyear": {
        "2021": "value_index",
        "2022": "value_index",
        "2023": "value_index"
      },
      "exclude": ["afford_index"],
      "reversed": true,
      "reversedcolors": true
    }
  ]}

  return (
    <section className='flex flex-row flex-wrap gap-16 max-w-full w-full mt-4'>
      <div className='flex-col flex-1 max-w-full min-w-[200px]'>
        <div className='font-heading text-3xl mt-4'>
          What is the Healthcare Value Index?
        </div>
        <p className='text-lg mt-4'>
          The Healthcare Value Index gauges the perceived value of care based on how respondents view the following:
        </p>
        <Collapse items={questions} accordion bordered={false} defaultActiveKey={['1']} className='font-medium text-lg mt-6' expandIcon={({ isActive }) => <CaretRightOutlined style={{color: '#1193b9', fontSize: '1em'}} rotate={isActive ? 90 : 0} />}/>
      </div>
      <div className='flex-col max-w-full flex-1 w-[400px]'>
        <DataContent config={graph}/>
      </div>
    </section>

  )

}