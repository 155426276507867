
import { useState, useRef } from 'react'
import { Select, Segmented, Empty } from 'antd'
import { op } from 'arquero'
import { useAtomValue } from 'jotai'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import { Plot, download } from 'src/state/util.js'
import { get_data } from 'src/state/requests.js'
import { filters, MINIMUM_N, color, usePageData } from '/src/state/state.js'

export default function GraphMultiYearIndex (props) {

  // Load necessary data and properties

  const config = props.config
  const style = config.style ? config.style : null
  const title = config.title ? config.title : ''
  const question = config.question ? config.question : null

  const {
    config_data,
    report_status,
    report_data
  } = usePageData()
  
  const years = Object.keys(config.multiyear)
  let loading = (report_status != 'success')
  const sources = years.map((year) => {
    const { status, data, error } = get_data(year) 
    if (status === 'success') return data
    else loading = true
  })
  
  // Generate and manage segmented values
  const index = report_data.demographics.find((demo) => demo.name == config.index)

  const params = Object.keys(index.options).map((resp) => {
    return {
      label: index.options[resp],
      value: parseInt(resp),
      disabled: false
    }
  })
  const [$param, setParam] = useState([parseInt(params[0].value)])
  if ($param.length == 1) {
    const curr = params.find((param) => param.value == $param[0])
    if (curr)
      curr.disabled = true
  }

  const yearParams = years.map((year) => {
    return {
      label: config_data.reports[year].label,
      value: year
    }
  })
  const [$year, setYear] = useState([years[years.length - 1]])

  const [ $percent, setPercent ] = useState(true)
  function perc (val) {
    setPercent((val == '% Relative'))
  }

  const $filters = useAtomValue(filters)

  // Image Download
  const ref = useRef(null)

  // Don't compute and draw empty if still loading.
  if (loading) return null

  // Download information
  const payload = {
    label: config.multiyear[years[0]] + '_indexed',
    identifier: config.multiyear[years[0]],
    title: config.title,
    question: config.question
  }

  // Compute graphs
  const responses = {}
  let small_data = false
  years.forEach((year, idx) => {

    let df = sources[idx]

    // Filter responses based on given filter
    Object.keys($filters).forEach((filter) => {
      if ($filters[filter].length > 0) {
        df = df.params({ 
          name: filter,
          accepted: $filters[filter].map(Number)
        }).filter((d, $) => op.includes($.accepted, d[$.name]))
      }
    })

    // Filter nulls
    const id = config.multiyear[year]
    const counts = df.params({ id: id }).filter((d, $) => d[$.id] != null)
    
    // Filter by param
    const selected = 
      counts.params({ id: config.index, param: $param })
        .filter((d, $) => op.includes($.param, d[$.id]))
    
    // Get total number of responses (after filtering nulls)
    const full = selected.rollup({
      count: op.count(),
      sum: op.sum('WEIGHT')
    })
    const fcnt = full.get('count', 0)
    const fsum = full.get('sum', 0)

    if (fcnt < MINIMUM_N) small_data = true

    // Group by demographic
    const grouped = selected.groupby(id).rollup({
      count: op.count(),
      sum: op.sum('WEIGHT')
    })

    // Generate counts and percentages
    const obj = grouped.objects()
    obj.forEach((row) => {
      const resp = row[id]
      const cnt = row['count']
      const sum = row['sum']
      if (!responses.hasOwnProperty(year))
        responses[year] = {}
      responses[year][resp] = {
        sum: sum,
        count: cnt,
        percent: sum / fsum
      }
    })
  })

  const data = Object.keys(responses).filter((year) => $year.includes(year)).map((year, idx) => {
    const keys = Object.keys(config.response)
    const vals = responses[year]
    return {
      x: keys.map(key => config.response[key]),
      y: ($percent) ? keys.map(i => (vals[i]) ? vals[i].percent : 0) : keys.map(i => (vals[i]) ? vals[i].sum : 0),
      customdata: ($percent) ? 
        keys.map(i => (vals[i]) ? [(vals[i].count).toLocaleString()] : ['0']) : 
        keys.map(i => (vals[i]) ? [Math.round(vals[i].percent * 100)] : ['0']),
      type: 'scatter',
      hovertemplate: '<span style="font-weight: 500;">' + $param.map((param) => index.options[param]).join(' + ') + 
        '</span> (%{x}, ' + config_data.reports[year].label + ')<br>' +
        (($percent) ? '%{y} (%{customdata[0]} Responses)' : '%{customdata[0]} (%{y} Responses)') +
        '<extra></extra>',
      name: config_data.reports[year].label,
      marker: {
        color: color(7, idx),
        size: 14
      },
      line: {
        color: color(7, idx),
        width: 7
      }
    }
  })

  return (
    <div className='flex flex-col w-full p-4 rounded-lg border bg-white' style={style} ref={ref}> 
      <div className='flex items-start gap-4'>
        <div className='flex flex-col flex-1 gap-4'>
          <span className='text-2xl font-heading'>{ title }</span>
        </div>
        <div className='imgrmv'>
          <Button 
            shape='circle' 
            icon={<DownloadOutlined className='pointer-events-none' />} 
            size={12} 
            onClick={() => { download(ref, payload) }}
            data-label={ payload.label }
            data-identifier={ payload.identifier }
            data-title={ payload.title }
            data-question={ payload.question }
            id={ 'dl_' + payload.label} />
        </div>
        <div className='imgshow hidden flex-[0.75]'>
          <img className='float-right' src='./images/wh-g-graph.png'/>
        </div>
      </div>
      <div className='flex flex-col py-4 self-stretch'>
        <div className='flex flex-wrap items-start gap-3'>
          <div className='flex items-center'>
            <Segmented 
              options={['% Relative', '# Absolute']}
              onChange={perc}
            />
          </div>
          <div className='flex flex-wrap items-center'>
            <span className='pr-3'>Selected Subset</span>
            <Select mode='multiple' showSearch={false} options={ params } value={$param} onChange={setParam}/>
          </div>
          <div className='flex flex-wrap items-center'>
            <span className='pr-3'>Year</span>
            <Select mode='multiple' showSearch={false} options={ yearParams } value={$year} onChange={setYear}/>
          </div>
        </div>
      </div>
      {
        (small_data) ?
        <div className='px-4 py-10'><Empty description={'Fewer than ' + MINIMUM_N + ' responses. Please adjust graph filters.'}/></div> :
        <Plot
          data={data}
          layout={ {
            autosize: true, 
            showlegend: true,
            margin: {
              l: 20,
              r: 20,
              b: 90,
              t: 0,
              pad: 20
            },
            font: {
              family: '"patron", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
              size: 14,
              color: '#4B5563'
            },
            hoverlabel: {
              font: {
                family: '"patron", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
                size: 14
              },
            },
            yaxis: {
              title: {
                text: ($percent ? 'Percent of ' : '') + 
                  (($param.length == params.length) ? 'Respondents' : 
                    $param.map(p => index.options[p]).join(' + <br>')),
                standoff: 30
              },
              tickformat: $percent ? ',.0%' : ',.0f',
              automargin: true,
              fixedrange: true,
              rangemode: 'tozero',
              zerolinecolor: '#BBB'
            },
            xaxis: {
              tickmode: 'array',
              automargin: true,
              autorange: 'reversed',
              fixedrange: true,
              showgrid: false
            },
            legend: {
              xanchor: 'center',
              x: 0.5,
              yref: 'container',
              yanchor: 'bottom',
              y: 0.03,
              orientation: 'h',
              traceorder: 'normal',
              itemclick: false,
              itemdoubleclick: false
            },
            dragmode: false
          } }
          config={ {
            displayModeBar: false
          } }
          useResizeHandler={true}
          className='w-full flex-1 min-h-[450px]'
        />
      }
      {
        question ? (
          <>
            <div className='py-2'>
              <div className='uppercase font-bold text-gray-500 hidden'>Question</div>
              { question }
            </div>
          </>
        ) : null
      }
      <div className='py-2 hidden'>
        <div className='uppercase font-bold text-gray-500'>Source</div>
        West Health-Gallup. See <a className='text-wh-blue hover:underline' href='https://www.westhealth.org/public-opinion/' target='_blank'>West Health-Gallup Data Dashboard</a> for full details.
      </div>
    </div>
  )
}