import { Link } from 'react-router-dom'
import { useState } from 'react'
import { motion } from 'framer-motion'

export default function StruggleHealthcare2 (props) {

  return (
    <>
      <section className='flex flex-row flex-col-reverse justify-center items-center p-8 pt-32 rounded-lg'>

        <div className='flex flex-col max-w-full flex-1 items-center'>
          <div className='flex flex-col items-start w-[500px] max-w-[90%] mt-8'>
            <motion.div 
              className='text-3xl font-heading'
              initial={{ opacity: 0, y:-50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              45% of all Americans struggle<br/>
              to afford healthcare.
            </motion.div>
          </div>
        </div>

        <div className='flex flex-col flex-1 max-w-full items-center'>
          <div className='relative max-w-[90%]'>
            <img className='h-[400px] max-w-full animate-pulse' src='./images/Stress04.svg'/>
          </div>
        </div>

      </section>
    </>
  )
  
}