import DataContent from '../DataContent'

export default function Container (props) {

  const style = props.config.style ? props.config.style : null
  const config = props.config ? props.config : {}
  const borderless = props.config.borderless ? props.config.borderless : false
  const border = borderless ? '' : 'rounded border'

  return (
    <section className={'flex flex-wrap p-4 justify-around content-start gap-4 ' + border} style={style}>
      {
        config.title ? (
          <div className='flex flex-1 items-start gap-4'>
            <span className='text-xl font-bold'>{ config.title }</span>
          </div> 
        ) : null
      }
      <DataContent config={config}/>
    </section> 
  )

}