
import GraphStandard from './graphs/GraphStandard.jsx'
import GraphStacked from './graphs/GraphStacked.jsx'
import GraphMultiYear from './graphs/GraphMultiYear.jsx'
import GraphMultiIndex from './graphs/GraphMultiIndex.jsx'

import TabsBasic from './layout/TabsBasic.jsx'
import TabsSlider from './layout/TabsSlider.jsx'
import Container from './layout/Container.jsx'
import Text from './layout/Text.jsx'
import List from './layout/List.jsx'
import Row from './layout/Row.jsx'
import Column from './layout/Column.jsx'
import Collapse from './layout/Collapse.jsx'
import Filters from './layout/Filters.jsx'
import Navigation from './layout/Navigation.jsx'
import Component from './layout/Component.jsx'

import { usePageData } from '../state/state.js'

import { Spin } from 'antd'

export default function DataContent (props) {
  
  let config = (props.config) ? props.config : null
  const {
    page,
    report_status,
    report_data
  } = usePageData()

  if (config == null && report_status == 'success')
    config = report_data

  if (report_status != 'success' || config == null) {
    return (
      <div className='flex flex-col pt-16 w-full items-center'>
        <Spin size='large'/>
      </div>
    )
  }

  return (
    <section className='flex flex-wrap w-full justify-between items-start gap-4 overflow-x-auto'>
      {
        config.children ? config.children.map((entry, idx) => {
          idx = page + '-' + idx
          if (entry.hasOwnProperty('type')) {
            switch (entry.type) {
              case 'disabled':
                return null
                break;
              case 'multipart':
                return <GraphStacked config={entry} key={idx}/>
                break;
              case 'multiyear':
                return <GraphMultiYear config={entry} key={idx}/>
                break;
              case 'multiyear-index':
                return <GraphMultiIndex config={entry} key={idx}/>
                break;
              case 'tabs':
                return <TabsBasic config={entry} key={idx}/>
                break;
              case 'tabs-slider':
                return <TabsSlider config={entry} key={idx}/>
                break;
              case 'container':
                return <Container config={entry} key={idx}/>
                break;
              case 'row':
                return <Row config={entry} key={idx}/>
                break;
              case 'col':
              case 'column':
                return <Column config={entry} key={idx}/>
                break;
              case 'text':
                return <Text config={entry} key={idx}/>
                break;
              case 'list':
                return <List config={entry} key={idx}/>
                break;
              case 'collapse':
                return <Collapse config={entry} key={idx}/>
                break;
              case 'filters':
                return <Filters config={entry} key={idx}/>
                break;
              case 'navigation':
                return <Navigation config={entry} key={idx}/>
                break;
              case 'component':
                return <Component config={entry} key={idx}/>
                break;
            }
          } else if (entry.hasOwnProperty('question')) {
            /* inferred graph type */
            if (entry.hasOwnProperty('multiyear')) {
              /* multiyear */
              return <GraphMultiYear config={entry} key={idx}/>
            } else if (entry.config?.hasOwnProperty('multipart')) {
              /* multipart */
              return <GraphStacked config={entry} key={idx}/>
            } else if (entry.config?.hasOwnProperty('allapply') && entry.config?.allapply) {
              /* select all that apply */
              return null
            } else {
              /* standard */
              return <GraphStandard config={entry} key={idx}/>
            }
          }
          return null
        }) : null
      }
    </section> 
  )

}