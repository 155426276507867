
import { op } from 'arquero'
import { useAtomValue } from 'jotai'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { useRef } from 'react'

import { Plot, download } from 'src/state/util.js'
import { get_data } from 'src/state/requests.js'
import { filters, color, usePageData } from 'src/state/state.js'

export default function GraphStacked (props) {

  const style = props.config.style ? props.config.style : null

  const config = props.config

  const {
    page,
    report_status,
    report_data
  } = usePageData()
  let { status: data_status, data: df, error: data_error } = get_data(page)

  const $filters = useAtomValue(filters)

  const ref = useRef(null)

  // Missing report
  if (report_status != 'success') {
    return null
  }

  // Missing config
  if (!report_data.questions.hasOwnProperty(config.question)) {
    return null
  }

  const properties = report_data.questions[config.question]
  const title = properties.title ? properties.title : null
  const question = properties.question ? properties.question : null

  const payload = {
    label: page + '_' + config.question,
    identifier: config.question,
    title: properties.title,
    question: properties.question
  }

  // Get data

  let data = []
  let ncnt = 0
  if (data_status == 'success') {

    // Filter responses based on given filter
    Object.keys($filters).forEach((filter) => {
      if ($filters[filter].length > 0) {
        df = df.params({ 
          name: filter,
          accepted: $filters[filter].map(Number)
        }).filter((d, $) => op.includes($.accepted, d[$.name]))
      }
    })

    let most_responses = 0
    let most_responses_subquestion = null

    const responses = []
    config.config.multipart.forEach((subquestion) => {
      
      // Filter nulls
      const counts = df.params({ id: subquestion }).filter((d, $) => d[$.id] != null)

      // Get total number of responses
      const full = counts.rollup({
        count: op.count(),
        sum: op.sum('WEIGHT')
      })

      ncnt = full.get('count', 0)
      const sum = full.get('sum', 0)

      // Group by response
      const rn = {}
      rn[subquestion] = 'response'

      const grouped = counts.groupby(subquestion).rollup({ 
        count: op.count(),
        sum: op.sum('WEIGHT')
      }).params({ total: sum }).derive({ percent: (d, $) => d.sum / $.total }).rename(rn)

      // Generate counts & percentages
      const obj = grouped.objects()
      if (obj.length > most_responses) {
        most_responses = obj.length
        most_responses_subquestion = subquestion
      }

      const sq_val = {
        question: subquestion,
        responses: {}
      }
      obj.forEach((resp) => {
        sq_val.responses[resp.response] = resp
      })

      responses.push(sq_val)

    })

    const response_reference = report_data.questions[most_responses_subquestion].response
    const reference_keys = Object.keys(response_reference)

    data = reference_keys.map((response, idx) => {
      return {
        x: responses.map((resp) => 
          resp.responses.hasOwnProperty(response) ? 
          resp.responses[response].percent : 
          null),
        y: responses.map((resp) => report_data.questions[resp.question].question.replace(/[\s\S]{1,46}(?!\S)/g, '$&<br>').slice(0, -4)),
        text: responses.map((resp) =>
          resp.responses.hasOwnProperty(response) ?
          Math.round(100 * resp.responses[response].percent) + '%' :
          null),
        customdata: responses.map((resp) =>
          resp.responses.hasOwnProperty(response) ?
          [(resp.responses[response].count).toLocaleString()] : 
          [null]),
        hovertemplate: (reference_keys.length == 1) ? 
          responses.map((resp) => '<span style="font-weight: 500;">' + report_data.questions[resp.question].question + '</span><br>' +
          '%{x} (%{customdata[0]} Responses)' +
          '<extra></extra>') :
          '<span style="font-weight: 500;">' + response_reference[response] + '</span><br>' +
          '%{x} (%{customdata[0]} Responses)' +
          '<extra></extra>',
        type: 'bar',
        orientation: 'h',
        name: response_reference[response],
        marker: {
          color: (reference_keys.length == 1) ? responses.map((resp, ridx) => color(responses.length, ridx)) : color(responses.length, idx)
        }
      }
    })

  }

  // Generate layout

  let layout = {
    autosize: true,
    barmode: 'stack',
    height: data.length > 0 ? (100 + data[0].y.length * 70) : 300,
    margin: {
      l: 20,
      r: 20,
      b: 110,
      t: 20,
      pad: 20
    },
    font: {
      family: '"patron", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
      size: 14,
      color: '#4B5563'
    },
    hoverlabel: {
      font: {
        family: '"patron", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
        size: 14
      },
    },
    yaxis: {
      title: {
        text: 'Responses',
        standoff: 30
      },
      automargin: true,
      fixedrange: true,
      autorange: 'reversed'
    },
    xaxis: {
      tickformat: ',.0%',
      title: {
        text: 'Percentages'
      },
      fixedrange: true,
      zeroline: false
    },
    legend: {
      xanchor: 'center',
      x: 0.5,
      yref: 'container',
      yanchor: 'bottom',
      y: 0.03,
      orientation: 'h',
      traceorder: 'normal',
      itemclick: false,
      itemdoubleclick: false
    },
    dragmode: false
  }

  return (
    <div className='flex flex-col max-w-[99%] basis-[99%] grow self-stretch p-4 rounded-lg border bg-white' style={style} ref={ref}> 
      <div className='flex items-start gap-4'>
        <div className='flex-1 text-2xl font-heading'>{ title }</div>
        <div className='imgrmv'>
          <Button 
            shape='circle' 
            icon={<DownloadOutlined className='pointer-events-none' />} 
            size={12} 
            onClick={() => { download(ref, payload) } }
            data-label={ payload.label }
            data-identifier={ payload.identifier }
            data-title={ payload.title }
            data-question={ payload.question }
            id={ 'dl_' + payload.label }/>
        </div>
        <div className='imgshow hidden flex-[0.75]'>
          <img className='float-right' src='./images/wh-g-graph.png'/>
        </div>
      </div>
      {
        question ? (
          <>
            <div className='py-2 mt-2 text-base font-light'>
              <span className='uppercase font-bold text-gray-500 mr-2 hidden'>Question</span>
              { properties.question }
              { ' (N=' + ncnt + ')' }
            </div>
          </>
        ) : null
      }
      <div className='overflow-x-auto'>
        <Plot
          data={ data }
          layout={ layout }
          config={ { displayModeBar: false } }
          useResizeHandler={true}
          className='w-full min-w-[1000px] flex-1'
        />
      </div>
      <div className='py-2'>
        <span>Source: </span>
        {
          report_data.source ? report_data.source : "West Health-Gallup Survey"
        }
      </div>
      <div className='py-2 hidden'>
        <div className='uppercase font-bold text-gray-500'>Source</div>
        {
          report_data.source ? report_data.source : "West Health-Gallup Survey"
        }
        <br/>
        See <a className='text-wh-blue hover:underline' href='https://www.westhealth.org/public-opinion/' target='_blank'>West Health-Gallup Data Dashboard</a> for full details.
      </div>
    </div> 
  )

}