
import { op } from 'arquero'
import { useAtomValue } from 'jotai'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { useRef } from 'react'

import { Plot, download } from 'src/state/util.js'
import { get_data } from 'src/state/requests.js'
import { filters, colorarray, usePageData } from 'src/state/state.js'

export default function GraphStandard (props) {

  const config = props.config
  const style = config.style ? config.style : null
  const question = config.question ? config.question : null

  const {
    page,
    report_status,
    report_data
  } = usePageData()

  let { status: data_status, data: df, error: data_error } = get_data(page)

  const $filters = useAtomValue(filters)

  const ref = useRef(null)

  // Missing report
  if (report_status != 'success') {
    return null
  }

  // Missing config
  if (!report_data.questions.hasOwnProperty(config.question)) {
    return null
  }

  // Skip certain types
  if (typeof report_data.questions[config.question] == 'string') {
    return null
  }

  const properties = report_data.questions[config.question]

  const payload = {
    label: page + '_' + question,
    identifier: question,
    title: properties.title,
    question: properties.question
  }

  // Get data

  let data = {}
  let ncnt = 0

  if (data_status == 'success') {

    // Filter responses based on given filter
    Object.keys($filters).forEach((filter) => {
      if ($filters[filter].length > 0) {
        df = df.params({ 
          name: filter,
          accepted: $filters[filter].map(Number)
        }).filter((d, $) => op.includes($.accepted, d[$.name]))
      }
    })

    // Filter nulls
    const counts = df.params({ id: question }).filter((d, $) => d[$.id] != null)

    // Get total number of responses
    const full = counts.rollup({
      count: op.count(),
      sum: op.sum('WEIGHT')
    })

    ncnt = full.get('count', 0)
    const sum = full.get('sum', 0)

    // Group by response
    const grouped = counts.groupby(question).rollup({
      count: op.count(),
      sum: op.sum('WEIGHT')
    })

    // Generate counts & percentages
    const responses = grouped.objects().filter((a) => properties.response.hasOwnProperty(a[question])).sort((a, b) => a[question] - b[question])

    data = {
      x: responses.map((resp) => resp.sum / sum),
      y: responses.map((resp) => properties.response[resp[question]].replace(/[\s\S]{1,46}(?!\S)/g, '$&<br>').slice(0, -4)),
      text: responses.map((resp) => Math.round(100 * resp.sum / sum) + '%'),
      customdata: responses.map((resp) => [(resp.count).toLocaleString()]),
      type: 'bar',
      orientation: 'h',
      hovertemplate: '<span style="font-weight: 500;">%{y}</span><br>' +
        '%{x} (%{customdata[0]} Responses)' +
        '<extra></extra>',
      marker: {
        color: colorarray(responses.length)
      }
    }

  }
  
  // Generate layout

  let layout = {
    autosize: true,
    height: data.y ? (100 + data.y.length * 70) : 300,
    margin: {
      l: 20,
      r: 20,
      b: 90,
      t: 20,
      pad: 20
    },
    font: {
      family: '"patron", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
      size: 14,
      color: '#4B5563'
    },
    hoverlabel: {
      font: {
        family: '"patron", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
        size: 14
      },
    },
    yaxis: {
      title: {
        text: 'Response',
        standoff: 30
      },
      automargin: true,
      autorange: 'reversed',
      fixedrange: true,
    },
    xaxis: {
      tickformat: ',.0%',
      title: {
        text: 'Percentages'
      },
      fixedrange: true,
      zeroline: false
    },
    dragmode: false
  }

  return (
    <div className='flex flex-col basis-[49%] min-w-[500px] w-[640px] grow max-w-full p-4 rounded-lg border bg-white' style={style} ref={ref}> 
      <div className='flex items-start gap-4'>
        <div className='flex-1 text-2xl font-heading'>{ properties.title }</div>
        <div className='imgrmv'>
          <Button 
            shape='circle' 
            icon={<DownloadOutlined className='pointer-events-none' />} 
            size={12} 
            onClick={() => { download(ref, payload) }}
            data-label={ payload.label }
            data-identifier={ payload.identifier }
            data-title={ payload.title }
            data-question={ payload.question }
            id={ 'dl_' + payload.label }/>
        </div>
        <div className='imgshow hidden flex-[0.75]'>
          <img className='float-right' src='./images/wh-g-graph.png'/>
        </div>
      </div>
      {
        question ? (
          <>
            <div className='py-2 mt-2 text-base font-light'>
              <span className='uppercase font-bold text-gray-500 mr-2 hidden'>Question</span>
              { properties.question }
              { ' (N=' + ncnt + ')' }
            </div>
          </>
        ) : null
      }
      <Plot
        data={[data]}
        layout={ layout }
        config={ { displayModeBar: false } }
        useResizeHandler={true}
        className='w-full max-w-full'
      />
      <div className='py-2'>
        <span>Source: </span>
        {
          report_data.source ? report_data.source : "West Health-Gallup Survey"
        }
      </div>
    </div> 
  )

}