import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, state = {} } = useLocation();

  useEffect(() => {
    if (state && state.fresh) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant"
      });
    }
  }, [pathname, state]);

  return null;
}