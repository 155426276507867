
import { Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useSetAtom } from 'jotai'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { filters, usePageData } from '../state/state'

export default function Navigation (props) {

  const location = useLocation()

  const setFilters = useSetAtom(filters)
  const {
    curr_page,
    config_status,
    config_data
  } = usePageData()

  const title = (curr_page) ? 
      curr_page.label + ' - West Health-Gallup National Healthcare & Aging Data Dashboard' :
      'West Health-Gallup National Healthcare & Aging Data Dashboard'

  if (config_status != 'success') return null

  function clearFilters() {
    setFilters({})
  }

  return (
    <section className='flex w-full flex-col md:flex-row items-stretch justify-center'>
      <Helmet>
        <title>{ title }</title>
      </Helmet>
      {
        config_data.tabs.map((tab) => {
          return (
            <div 
              key={tab.name} 
              className={'group relative flex flex-1 text-xl border-b border-slate-400 cursor-pointer ' + ((tab.matches) ? 'font-medium' : 'font-light')}>
              { 
                (tab.pages) ? (
                  <Dropdown dropdownRender={() => (
                    <div className='flex flex-col divide-y divide-slate-200 text-lg bg-white border border-slate-200 px-4 drop-shadow'>
                      {
                        tab.pages.map((pg, idx) => {
                          return (
                            <div key={idx}>
                              <Link id={ 'nav_' + pg.id } to={ pg.init_url } className={(pg == curr_page) ? 'font-medium' : 'font-light'} onClick={clearFilters}>
                                <div className='py-4'>
                                  { pg.label }
                                </div>
                              </Link>
                            </div>
                          )
                        })
                      }
                    </div>
                  )}  placement='bottomLeft'>
                    <Link id={ 'nav_' + tab.id } to={ tab.init_url } className='flex w-full items-center justify-between' onClick={clearFilters}>
                      <div className='flex flex-row w-full justify-center gap-4 p-4 whitespace-nowrap'>
                        { tab.name }
                        <DownOutlined className='text-sm'/>
                      </div>
                    </Link>
                  </Dropdown>
                ) : (
                  <Link id={ 'nav_' + tab.id } to={ tab.init_url } className='flex flex-row w-full justify-center p-4 whitespace-nowrap' onClick={clearFilters}>
                    { tab.name }
                  </Link>
                )
              }
              <div className={((tab.matches) ? 'w-full' : 'w-0') + ' absolute left-0 bottom-[-2px] h-1 bg-[#0285a9] group-hover:w-full transition-all duration-300'}/>
            </div>
          )
        })
      }
    </section>
  )

}