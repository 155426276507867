import { Link } from 'react-router-dom'
import { ResponsivePie } from '@nivo/pie'
import { useState } from 'react'
import { motion } from 'framer-motion'

export default function ReportCard (props) {

  const textless = props.textless || false
  const pieProps = props.pieProps || {}
  const width = props.width || '500px'
  const height = props.height || '500px'
  const padding = props.padding || '0'

  const years = ['2021', '2022', '2023']

  // Data State

  const [$year, setYear] = useState(props.year || years[years.length - 1])

  // Data

  const data = props.data || {
    2022: { grades: [
      { id: 'A', value: 0.04, color: '#0f737f', text_color: 'white' },
      { id: 'B', value: 0.17, color: '#8aabb1', text_color: 'black' },
      { id: 'C', value: 0.34, color: '#d2d3d4', text_color: 'black' },
      { id: 'D', value: 0.30, color: '#dd8e9f', text_color: 'black' },
      { id: 'F', value: 0.14, color: '#bb2644', text_color: 'white' }
    ], avg: 'C-' },
    2023: { grades: [
      { id: 'A', value: 0.04, color: 'white', text_color: 'black' },
      { id: 'B', value: 0.16, color: 'white', text_color: 'black' },
      { id: 'C', value: 0.34, color: 'white', text_color: 'black' },
      { id: 'D', value: 0.35, color: '#aad1de', text_color: 'black' },
      { id: 'F', value: 0.12, color: '#aad1de', text_color: 'black' }
    ], avg: 'C-' },
  }
  
  return (
    <>
      <section className='flex flex-row flex-col-reverse max-w-full justify-center items-center p-8'>
        { textless == false ? (
          <div className='flex flex-col flex-1 items-center hidden'>
            <div className='flex flex-col items-end max-w-xl mt-16'>
              <motion.div 
                className='text-3xl font-bold'
                initial={{ opacity: 0, y:-50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                Many Americans rate the quality and value of the U.S. healthcare system negatively.
              </motion.div>
              <Link to={'/report/' + $year + '/5'} state={{ fresh: true }}>
                <div className='link text-sm self-start mt-4' href='#'>
                  <span className='relative'>LEARN MORE</span>
                </div>
              </Link>
            </div>
          </div>
        ) : null }
        <div className='flex flex-col flex-1 max-w-full items-center'>
          <div className='relative max-w-full h-[300px] md:h-[500px]' style={{ width: width, padding: padding }}>
            <ResponsivePie
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                data={data[$year].grades}
                colors={ ({data}) => data.color }
                startAngle={-90}
                endAngle={270}
                padAngle={2}
                cornerRadius={4}
                innerRadius={0.7}
                theme={{ text: { fontSize: 22 } }}
                arcLabel='id'
                enableArcLabels={true}
                arcLabelsTextColor={ ({data}) => data.text_color }
                enableArcLinkLabels={false}
                isInteractive={false}
                animate={false}
                {...pieProps}
                />
            <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center'>
              <div className='flex flex-col items-center gap-1'>
                <span className='text-sm text-center'>
                  Average Grade
                </span>
                <span className='text-6xl xl:text-8xl font-heading'>
                  { data[$year].avg }
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
  
}