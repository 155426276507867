
import { Dropdown, Checkbox, Tag } from 'antd'
import { useAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'

import { get_config, get_report } from '../state/requests'
import { filters, usePageData } from '../state/state'

export default function Filters (props) {

  // Page Data
  
  const [ $filters, setFilters ] = useAtom(filters)

  const {
    report_data,
    report_status,
    curr_page
  } = usePageData()

  if (report_status != 'success') return null

  const filterlist = report_data.demographics.filter((demo) => demo.filterable !== false)

  // Filters

  function resetFilter () {
    setFilters({})
  }

  function setFilter (e, demo, val) {

    if (!Array.isArray($filters[demo])) {
      $filters[demo] = []
    }

    if (e.target.checked && $filters[demo].indexOf(val) === -1) {
      $filters[demo].push(val)
    } else if (!e.target.checked) {
      const loc = $filters[demo].indexOf(val)
      if (loc !== -1) {
        $filters[demo].splice(loc, 1)
      }
    }

    setFilters(Object.assign({}, $filters))

  }

  function clearDemo (demo) {
    $filters[demo] = []
    setFilters(Object.assign({}, $filters))
  }

  function selectAll (demo) {
    $filters[demo] = Object.keys(filterlist.find((fi) => fi.name == demo).options)
    setFilters(Object.assign({}, $filters))
  }

  return (
    <section className='flex flex-col w-full'>
      <div className='flex flex-col md:flex-row flex-wrap w-full max-w-full pt-4 border-b border-slate-800 font-light'>
        {
          filterlist.map((filter, idx) => {
            return (
              <div key={filter.name} className='relative flex flex-1 h-full w-full hover:bg-[#0b2139] hover:text-white hover:transition-none transition-all duration-300 group'>
                <div className='absolute right-[-1px] h-full w-px bg-[#0b2139] hidden group-hover:block'/>
                <Dropdown dropdownRender={() => (
                  <div className='flex flex-col divide-y divide-slate-200 text-lg bg-white border border-slate-200 px-4 drop-shadow'>
                    {
                      Object.keys(filter.options).map((opt, idx) => {
                        return (
                          <div key={idx}>
                            <Checkbox
                              id={ 'filter_' + filter.name + '_' + opt }
                              checked={($filters.hasOwnProperty(filter.name) && $filters[filter.name].includes(opt))}
                              onChange={(e) => { setFilter(e, filter.name, opt) }}
                              className='py-4 text-lg font-light w-full'>
                              <span className='select-none'>
                                { filter.options[opt] }
                              </span>
                            </Checkbox>
                          </div>
                        )
                      })
                    }
                    <button id={ 'filter_' + filter.name + '_all' } className='py-4 text-[#0285a9] hover:underline' onClick={(e) => { selectAll(filter.name) }}>
                      Select All
                    </button>
                  </div>
                )} placement={'bottomLeft'}>
                  <div className='flex flex-1 py-4 cursor-pointer border-slate-600 border-t md:border-t-0'>
                    <div className={'flex w-full items-center justify-between p-4 border-slate-600 ' + ((idx != 0) ? 'md:border-l' : '')}>
                      <span>
                        { filter.label }
                        <span className='pl-2 text-[#0285a9] hidden'>
                          { (Array.isArray($filters[filter.name]) && $filters[filter.name].length > 0) ? $filters[filter.name].length : null }
                        </span>
                      </span>  
                      <DownOutlined className='text-sm'/>
                    </div>
                  </div>
                </Dropdown>
              </div>
            )
          })
        }
      </div>
      <div className='flex flex-row flex-wrap w-full max-w-full overflow-hidden pt-4 gap-2'>
        <Tag className='text-base rounded-full flex items-center gap-2 m-0'>
          { curr_page.label }
        </Tag>
        {
          Object.keys($filters).map((fi) => {
            const prp = filterlist.find((f) => f.name == fi)
            const mxl = Object.keys(prp.options).length
            if ($filters[fi].length > 0) {
              if ($filters[fi].length == mxl) {
                return (
                  <Tag key={fi} className='text-base rounded-full flex items-center gap-2 m-0' closable onClose={() => clearDemo(fi)}>
                    { prp.label + ': All' }
                  </Tag>
                )
              } else {
                return (
                  <Tag key={fi} className='text-base rounded-full flex items-center gap-2 m-0' closable onClose={() => clearDemo(fi)}>
                    { prp.label + ': ' + $filters[fi].map((f) => prp.options[f]).join(', ') }
                  </Tag>
                )
              }
            }
            return null
          })
        }
      </div>
    </section>
  )

}