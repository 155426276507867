
import Navigation from './Navigation'
import ScrollToTop from'./ScrollToTop'

import { Link, useRouteError, isRouteErrorResponse } from 'react-router-dom'
import { Collapse } from 'antd'

export default function ErrorView (props) {

  const error = useRouteError()

  let errorMessage

  if (isRouteErrorResponse(error)) {
    errorMessage = error.error?.message || error.statusText
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else if (typeof error === 'string') {
    errorMessage = error
  } else {
    console.error(error)
    errorMessage = 'Unknown error'
  }

  const col = [
    {
      key: '1',
      label: 'Error Message for Developer',
      children: (
        <div className='text-sm'>
          <h1>{ error.status }</h1>
          { errorMessage }<br/><br/>
          { error.stack }<br/>
        </div>
      )
    }
  ]

  return (
    <>
      <ScrollToTop/>
      <section className='flex flex-col items-center py-4 md:w-[1276px] max-w-[95%] font-light'>
        <Navigation/>
        <section className='flex max-w-full flex-col items-center'>
          <div className='md:w-[1276px] max-w-full'>
            <div className='flex flex-col items-center justify-center p-8'>
              <img className='h-[200px] max-w-full mb-8' src='./images/Stress02.svg'/>
              <h2 className='text-2xl font-medium'>Whoops, you've ran into an unintentional error!</h2>
              <span>We're sorry about that. Please feel free to navigate away with the following links.</span>
              <div className='flex flex-wrap mt-8 gap-8'>
                <Link to='/'>
                  <div className='rounded-full relative px-8 p-4 transition-all overflow-hidden bg-slate-200 hover:text-black text-white duration-300' href='#'>
                    <span className='z-20 relative pointer-events-none'>Return To Intro</span>
                    <div className='z-10 absolute top-0 left-0 w-full h-full transition-all hover:opacity-0 bg-[linear-gradient(132deg,#04909C,#007DB1)] duration-300'/>
                  </div>
                </Link>
                <Link to='https://westhealth.org/'>
                  <div className='rounded-full relative px-8 p-4 transition-all overflow-hidden bg-slate-200 hover:text-black text-white duration-300' href='#'>
                    <span className='z-20 relative pointer-events-none'>Return To West Health</span>
                    <div className='z-10 absolute top-0 left-0 w-full h-full transition-all hover:opacity-0 bg-[linear-gradient(132deg,#04909C,#007DB1)] duration-300'/>
                  </div>
                </Link>
              </div>
              <div className='mt-8 max-w-[90%] w-[600px]'>
                <Collapse items={col} bordered={false}/>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  )

}