
import Plotly from 'plotly.js-basic-dist-min'
import createPlotlyComponent from 'react-plotly.js/factory'
export const Plot = createPlotlyComponent(Plotly)

import { toBlob } from 'html-to-image'
import { saveAs } from 'file-saver'
export function download (ref, payload) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(Object.assign({'event': 'wh.download'}, payload))
  if (ref.current != null) {
    ref.current.classList.add('imgtoggle')
    toBlob(ref.current, { cacheBust: true })
      .then((blob) => {
        ref.current.classList.remove('imgtoggle')
        saveAs(blob, 'westhealth_gallup_' + (payload.label).replace(/[^a-zA-Z0-9_]/g, '') + '.png')
      })
  }
}