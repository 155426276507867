
import { op } from 'arquero'
import { useState, useRef } from 'react'
import { useAtomValue } from 'jotai'
import { Button, Popover } from 'antd'
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons'

import { Spin, Segmented, Empty } from 'antd'

import { Plot, download } from 'src/state/util.js'
import { get_data } from 'src/state/requests.js'
import { filters, MINIMUM_N, color, usePageData } from '/src/state/state.js'

export default function GraphMultiYear (props) {

  // Load necessary data and properties

  const config = props.config
  const style = config.style ? config.style : null
  const title = config.title ? config.title : ''
  const question = config.question ? config.question : null
  const fullwidth = config.fullwidth ? config.fullwidth : false
  const reversed = config.reversed ? config.reversed : false
  const reversedcolors = config.reversedcolors ? config.reversedcolors : false
  const tooltips = config.tooltips ? config.tooltips : null

  const {
    config_data,
    report_status
  } = usePageData()

  const $filters = useAtomValue(filters)
  const [ $percent, setPercent ] = useState(true)

  const ref = useRef(null)

  function perc (val) {
    setPercent(!$percent)
  }

  const years = Object.keys(config.multiyear)
  let loading = (report_status != 'success')
  const sources = years.map((year) => {
    const { status, data, error } = get_data(year) 
    if (status === 'success') return data
    else loading = true
  })
  
  if (loading) return (
    <div className='flex flex-col w-full self-stretch p-4 py-40 rounded-lg border' style={style}>
      <Spin size='large'/>
    </div>
  )

  const payload = {
    label: config.multiyear[years[0]] + '_multiyear',
    identifier: config.multiyear[years[0]],
    title: config.title,
    question: question ? question : config.title
  }

  let small_data = false

  const responses = {}

  years.forEach((year, idx) => {

    // Fetch the data (in Arquero) for a provided year
    let df = sources[idx]

    // Check all current filters. These filters behave like allowlists.
    // These filters are used to view the data for specific demographics.
    Object.keys($filters).forEach((filter) => {
      // If a filter has any active values...
      if ($filters[filter].length > 0) {
        // Pass the permitted values into Arquero as parameters
        // and only permit rows that have a value on the allowlist through.
        df = df.params({ 
          name: filter,
          accepted: $filters[filter].map(Number)
        }).filter((d, $) => op.includes($.accepted, d[$.name]))
      }
    })
    
    // Grab the column ID for the desired data (for example, the Healthcare Affordability Index column)
    // This value is defined in a configuration JSON and could be "value_index", the column for the Value Index.
    const id = config.multiyear[year]

    // Filter any rows where the value of the desired data column is null.
    const counts = df.params({ id: id }).filter((d, $) => d[$.id] != null)

    // Get the total number of responses (unused) and sum of weights for the remaining non-null rows.
    const full = counts.rollup({
      count: op.count(),
      sum: op.sum('WEIGHT')
    })
    const fcnt = full.get('count', 0)
    const fsum = full.get('sum', 0)

    if (fcnt < MINIMUM_N) small_data = true

    // Group the data by unique responses for the non-null rows and generate columns for the total count of
    // each group, as well as the sum of weights for each group.
    const grouped = counts.groupby(id).rollup({
      count: op.count(),
      sum: op.sum('WEIGHT')
    })

    // Convert this table into a Javascript object for parsing.
    const obj = grouped.objects()

    // For each unique response...
    obj.forEach((row) => {

      if (!Object.keys(config.response).includes(row[id].toString()))
        return
      
      // Get the response id, total number of responses, and the sum of weight for all repsonses.
      const resp = row[id]
      const cnt = row['count']
      const sum = row['sum']

      if (!responses.hasOwnProperty(resp))
        responses[resp] = {}

      // Store all of the provided data
      responses[resp][year] = {
        sum: sum,
        count: cnt,
        percent: sum / fsum,
        cnt_pct: cnt / fcnt
      }
      
    })

  })

  const response_keys = Object.keys(responses)
  const predata = response_keys.map((resp, idx) => {
    const vals = Object.values(responses[resp])
    return {
      x: Object.keys(responses[resp]),
      y: ($percent) ? vals.map(i => i.percent) : vals.map(i => i.sum),
      width: 0.85,
      customdata: ($percent) ? 
        vals.map(i => [(i.count).toLocaleString(), i.sum]) : 
        vals.map(i => [Math.round(i.percent * 100)]),
      type: 'bar',
      hovertemplate: '<span style="font-weight: 500;">' + config.response[resp] + '</span> in %{x}<br>' +
        (($percent) ? '%{y} (%{customdata[0]} Responses)' : '%{customdata[0]}% (%{y} Responses)') +
        '<extra></extra>',
      name: config.response[resp],
      marker: {
        color: (reversedcolors) ? color(responses.length, idx) : color(responses.length, response_keys.length - idx - 1),
        line: {
          color: 'white',
          width: 1
        }
      }
    }
  })

  let data = (reversed) ? predata : predata.reverse()

  let layout = {
    autosize: true,
    barmode: 'stack',
    margin: {
      l: 20,
      r: 20,
      b: (tooltips) ? 60 : 90,
      t: 0,
      pad: 20
    },
    font: {
      family: '"patron", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
      size: 14,
      color: '#4B5563'
    },
    hoverlabel: {
      font: {
        family: '"patron", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
        size: 14
      },
    },
    yaxis: {
      title: {
        text: $percent ? 'Percent of Respondents' : 'Respondents',
        standoff: 30
      },
      tickformat: $percent ? ',.0%' : ',.0f',
      automargin: true,
      fixedrange: true,
      rangemode: 'tozero',
      zerolinecolor: '#BBB'
    },
    xaxis: {
      tickmode: 'array',
      tickvals: years,
      ticktext: years.map((year) => config_data.reports[year].label),
      automargin: true,
      range: [Math.min(...years)-1, Math.max(...years) + (fullwidth ? 4 : 1)],
      fixedrange: true,
      showgrid: false
    },
    showlegend: (tooltips == null),
    legend: {
      xanchor: 'center',
      x: 0.5,
      yref: 'container',
      yanchor: 'bottom',
      y: 0.03,
      orientation: 'h',
      traceorder: 'normal',
      itemclick: false,
      itemdoubleclick: false
    },
    dragmode: false,
  }

  return (
    <div className='flex flex-col w-full self-stretch p-4 rounded-lg border bg-white' style={style} ref={ref}> 
      <div className='flex items-start gap-4'>
        <div className='flex-1 font-heading text-2xl'>{ title }</div>
        <div className='imgrmv'>
          <Button 
            shape='circle' 
            icon={<DownloadOutlined className='pointer-events-none' />} 
            size={12} 
            onClick={() => { download(ref, payload) }}
            data-label={ payload.label }
            data-identifier={ payload.identifier }
            data-title={ payload.title }
            data-question={ payload.question }
            id={ 'dl_' + payload.label} />
        </div>
        <div className='imgshow hidden flex-[0.75]'>
          <img className='float-right' src='./images/wh-g-graph.png'/>
        </div>
      </div>
      <div className='flex flex-col py-2'>
        <div className='flex flex-wrap items-start gap-3'>
          <Segmented 
            options={['% Relative', '# Absolute']}
            onChange={perc}
          />
        </div>
      </div>
      {
        (small_data) ?
        <div className='px-4 py-10'><Empty description={'Fewer than ' + MINIMUM_N + ' responses. Please adjust graph filters.'}/></div> :
        <Plot
          data={data}
          layout={layout}
          config={ {
            displayModeBar: false
          } }
          useResizeHandler={true}
          className='w-full flex-1 min-h-[450px]'
        /> 
      }
      <div className='flex flex-wrap self-center gap-4 justify-center max-w-[90%]'>
        {
          (tooltips) ?
            response_keys.map((resp, idx) => {
              const tt_color = (reversedcolors) ? color(responses.length, idx) : color(responses.length, response_keys.length - idx - 1)
              return (
                <div className='flex gap-2 items-center' key={'tt-' + idx}>
                  <div className='rounded-full w-4 h-4' style={{ backgroundColor: tt_color }}></div>
                  <span>{ config.response[resp] }</span>
                  <Popover content={(
                      <div className='max-w-[300px]'>
                        { tooltips[resp] }
                      </div>
                    )} 
                    title={(
                      <span className='font-medium text-lg'>
                        { config.response[resp] }
                      </span>
                    )} 
                    className='cursor-pointer imgrmv'>
                    <InfoCircleOutlined/>
                  </Popover>
                </div>
              )
            })
            : null
        }
      </div>
      {
        question ? (
          <>
            <div className='py-2'>
              <div className='uppercase font-bold text-gray-500 hidden'>Question</div>
              { question }
            </div>
          </>
        ) : null
      }
    </div>
  )

}