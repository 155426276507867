
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Spin, Carousel } from 'antd'
import { LeftOutlined, RightOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { useScroll, useMotionValueEvent } from 'framer-motion'

import NationalMap from './infographics/NationalMapOptimized'
import StruggleHealthcare2 from './infographics/StruggleHealthcare2'
import ReportCardCircle from './infographics/ReportCardCircle'
import AnxietyBurden from './infographics/AnxietyBurden'

import Navigation from './Navigation'

import { get_config } from '../state/requests'

export default function OverView (props) {

  const { status } = get_config()
  const slider = useRef()
  const scrollTarget = useRef()
  const sectionA = useRef()
  const sectionB = useRef()
  const sectionC = useRef()
  const { scrollY } = useScroll()
  const [$currSection, setSection] = useState('A')
  const [$slide, setSlide] = useState(0)

  useMotionValueEvent(scrollY, 'change', (latest) => {
    const offset = 200
    if (sectionC.current && latest > (sectionC.current.getBoundingClientRect().top + window.scrollY - offset)) {
      if ($currSection != 'C')
        setSection('C')
    } else if (sectionB.current && latest > (sectionB.current.getBoundingClientRect().top + window.scrollY - offset)) {
      if ($currSection != 'B')
        setSection('B')
    } else if (sectionA.current && latest > (sectionA.current.getBoundingClientRect().top + window.scrollY - offset)) {
      if ($currSection != 'A')
        setSection('A')
    }
  })

  if (status != 'success') {
    return (
      <div className='flex flex-col pt-16 w-full items-center'>
        <Spin size='large'/>
      </div>
    )
  }

  function nextSlide() {
    setSlide($slide + 1)
  }

  function scroll(target) {
    const { current } = target
    if (current != null)
      current.scrollIntoView({ behavior: 'smooth' })
  }

  function category (heading, subheading, image, url, label) {
    return (
      <div className='flex flex-col md:flex-row flex-wrap max-w-[90%] gap-2 md:gap-12 items-center justify-center'>
        <div className='w-[150px] xl:w-[200px]'>
          <img src={image}></img>
        </div>
        <div className='flex flex-1 w-[600px] max-w-full flex-col gap-2'>
          <div className='flex flex-row gap-2 xl:gap-4 items-baseline font-heading flex-wrap justify-center self-start'>
            { heading }
          </div>
          <div className='text-lg xl:text-2xl font-light'>
            { subheading }
          </div>
          <Link id={ 'nav_learn_' + label } to={url} state={{ fresh: true }} className='underline text-[#007298] text-lg'>Learn More</Link>
        </div>
      </div>
    )
  }

  return (
    <>
      <section className='flex flex-col items-center py-4 md:w-[1276px] max-w-[95%]'>
        <Navigation/>
        <div className='flex md:w-[1276px] flex-col max-w-full lg:flex-row items-center justify-between'>
          <div className='flex flex-col items-start justify-center basis-[500px] max-w-[90%]'>
            <div className='flex flex-col mt-[10%] gap-2 max-w-full'>
              <span className='text-lg font-light'>
                West Health-Gallup
              </span>
              <h1 className='text-3xl md:text-5xl font-heading'>
                National Healthcare &<br/>
                Aging Data Dashboard<br/>
              </h1>
              <p className='font-light text-base md:text-lg mt-6'>
                An interactive data dashboard that organizes and summarizes nationally-representative West Health-Gallup survey data on healthcare and aging in America.
              </p>
              <button id='btn_scrollmore' className='mt-12 self-start' onClick={() => {scroll(scrollTarget)}}>
                <div className='flex flex-row items-stretch h-[4em] max-h-[4em] rounded-full relative bg-slate-200' href='#'>
                  <div className='flex shrink-0 aspect-square items-center justify-center rounded-full bg-[linear-gradient(132deg,#04909C,#007DB1)] text-white text-3xl'>
                    <ArrowDownOutlined className='animate-bounce'/>
                  </div>
                  <div className='flex flex-1 w-[12em] items-center justify-center pl-2 pr-4'>
                    Scroll to explore
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div className='relative flex flex-[0.9] max-w-full flex-col items-center justify-center overflow-hidden'>
            <button 
              onClick={() => { slider.current.prev() }} 
              className='absolute text-neutral-400 text-xl hover:scale-125 hover:bg-zinc-100/80 rounded-full transition left-4 py-4 px-2 top-1/2 z-20'>
              <LeftOutlined/>
            </button>
            <button 
              onClick={() => { slider.current.next() }}
              className='absolute text-neutral-400 text-xl hover:scale-125 hover:bg-zinc-100/80 rounded-full transition right-4 py-4 px-2 top-1/2 z-20'>
              <RightOutlined/>
            </button>
            <Carousel ref={slider} beforeChange={nextSlide} dots={{ className: 'dot' }}>
              <div className='max-w-full pb-4'>
                <NationalMap/>
              </div>
              <div className='max-w-full pb-4'>
                <StruggleHealthcare2/>
              </div>
              <div className='max-w-full pb-4'>
                <AnxietyBurden/>
              </div>
            </Carousel>
          </div>
        </div>
      </section>
      <section className='flex flex-col items-center max-w-full w-full bg-gradient-to-r from-[#0C1F37] to-[#013455] text-white mt-12'>
        <div className='flex relative md:w-[1276px] flex-col max-w-full items-center justify-center border-slate-200 py-16 gap-8'>
          <div ref={scrollTarget} className='absolute top-[-86px]'/>
          <div className='max-w-[80%] font-heading text-4xl md:text-5xl/tight font-light'>
            When asked to grade the U.S. healthcare system on <span className='font-black'>affordability</span>, 
            <span className='font-black'> access</span>, and <span className='font-black'>quality</span>,
            most Americans rate it poorly.
          </div>
          <div className='relative flex flex-1 max-w-full flex-col items-center justify-center overflow-hidden rounded'>
            <ReportCardCircle/>
          </div>
        </div>
      </section>
      <section className='flex flex-col xl:flex-row items-stretch xl:items-start justify-center md:w-[1276px] max-w-full'>
        <div className='sticky flex flex-1 xl:flex-auto flex-row xl:flex-col justify-center items-start xl:w-[270px] max-w-full bg-white font-normal text-xl md:text-2xl xl:text-3xl gap-4 px-12 top-20 py-8 border-b border-slate-400 xl:border-none xl:top-20 xl:pt-32 z-20'>
          <button onClick={() => {scroll(sectionA); }} className={'transition hover:text-[#007298] ' + (($currSection == 'A') ? 'text-[#1A1A1A]' : 'text-[#D2D2D2]')}>Affordability</button>
          <button onClick={() => {scroll(sectionB); }} className={'transition hover:text-[#007298] ' + (($currSection == 'B') ? 'text-[#1A1A1A]' : 'text-[#D2D2D2]')}>Access</button>
          <button onClick={() => {scroll(sectionC); }} className={'transition hover:text-[#007298] ' + (($currSection == 'C') ? 'text-[#1A1A1A]' : 'text-[#D2D2D2]')}>Quality</button>
        </div>
        <div className='flex xl:flex-1 flex-col items-center py-28 max-w-full z-10'>
          <div className='flex flex-col items-center gap-64 max-w-full'>
            <div className='flex flex-col gap-20 items-center relative max-w-full'>
              <div ref={sectionA} className='absolute top-[-200px] h-full pt-[200px] box-content'/>
              {
                category(
                  (<><span className='text-6xl xl:text-8xl font-light'>175</span><span className='text-3xl xl:text-5xl font-light'>Million</span></>),
                  'consider healthcare costs to be a financial burden.',
                  '/images/landing/01.svg',
                  '/data/2023/3',
                  'affordability_1'
                )
              }
              {
                category(
                  (<><span className='text-6xl xl:text-8xl font-light'>80</span><span className='text-3xl xl:text-5xl font-light'>Million</span></>),
                  'would not be able to access quality care if they needed it today.',
                  '/images/landing/02.png',
                  '/data/2023/0',
                  'affordability_2'
                )
              }
            </div>
            <div className='flex flex-col gap-20 items-center relative max-w-full'>
              <div ref={sectionB} className='absolute top-[-200px] h-full pt-[200px] box-content'/>
              {
                category(
                  (<><span className='text-6xl xl:text-8xl font-light'>47</span><span className='text-3xl xl:text-5xl font-light'>Million</span></>),
                  'report that they or a family member had health problems worsen after postponing care due to cost.',
                  '/images/landing/03.svg',
                  '/data/2022/3',
                  'access_1'
                )
              }
              {
                category(
                  (<><span className='text-6xl xl:text-8xl font-light'>13</span><span className='text-3xl xl:text-5xl font-light'>Million</span></>),
                  'know a friend or family member who died in the past year after not receiving treatment because they could not afford it. ',
                  '/images/landing/04.svg',
                  '/data/2022/3',
                  'access_2'
                )
              }
            </div>
            <div className='flex flex-col gap-20 items-center relative max-w-full'>
              <div ref={sectionC} className='absolute top-[-200px] h-full pt-[200px] box-content'/>
              {
                category(
                  (<><span className='text-6xl xl:text-8xl font-light'>89%</span></>),
                  'think Americans are paying too much for the quality of care they receive.',
                  '/images/landing/05.svg',
                  '/data/2023/0',
                  'quality_1'
                )
              }
              {
                category(
                  (<><span className='text-6xl xl:text-8xl font-light'>107</span><span className='text-3xl xl:text-5xl font-light'>Million</span></>),
                  'do not think their healthcare experiences are worth the cost of care.',
                  '/images/landing/06.svg',
                  '/data/2023/0',
                  'quality_2'
                )
              }
            </div>
          </div>
          <div className='w-[900px] pl-10 mt-20 max-w-[90%] font-light text-base xl:text-lg border-l-4 border-[#0288A5]'>
            Source: These findings are based on nationally representative data collected by Gallup in partnership with West Health over years 2021 to 2024. The population projections were estimated using survey sampling weights. 
            <br/><br/>
            Explore the <Link id='nav_footerindices' to='/trending/0/0' className='link linkm'>'Indices & Wellbeing' page</Link> to learn about the extent and impact of the high cost of care.
            Our entire catalog of questions can be found on the <Link id='nav_footerdeepdive' to='/data/2023' className='link linkm'>‘Deep Dive’ page</Link>.
          </div>
        </div>
      </section>
    </>
  )

}